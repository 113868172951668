import React, {useEffect, useState} from 'react'
import PageHeading from '../../components/common/PageHeading.js'
import {VIEW_UNIT} from '../../graph-ql/unit.js';
import {useParams} from "react-router-dom";
import Loading from '../../components/common/Loading.js';
import UnitNavigationTabs from '../../components/unit/view/navigation/UnitNavigationTabs.js';
import UnitViewDetails from '../../components/unit/view/UnitViewDetails.js';
import UnitViewOverview from '../../components/unit/view/UnitViewOverview.js';
import UnitViewTenants from '../../components/unit/view/UnitViewTenants.js';
import UnitViewIncome from '../../components/unit/view/UnitViewIncome.js';
import UnitViewExpenses from '../../components/unit/view/UnitViewExpenses.js';
import UnitViewInventory from '../../components/unit/view/UnitViewInventory.js';
import UnitViewMortgage from '../../components/unit/view/UnitViewMortgage.js';
import UnitViewListings from '../../components/unit/view/UnitViewListings.js';
import UnitViewInsurance from '../../components/unit/view/UnitViewInsurance.js';
import UnitViewUtilities from '../../components/unit/view/UnitViewUtilities.js';
import UnitViewTasks from '../../components/unit/view/UnitViewTasks.js';
import UnitViewContacts from '../../components/unit/view/UnitViewContacts.js';
import UnitViewMedia from '../../components/unit/view/UnitViewMedia.js';
import GraphQLClientWithCredentials from '../../services/GraphQLClientWithCredentials.js';
import UnitMutatePage from './UnitMutatePage.js';
import ClickAction from '../../components/common/action/ClickAction.js';
import { DELETE_UNIT } from '../../graph-ql/unit.js';
import ConfirmationModal from '../../components/common/ConfirmationModal.js';
import { useNavigate } from 'react-router-dom';

const UnitViewPage = () => {
  const [currentPage, setCurrentPage] = useState('overview');
  const [data, setData] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [shouldRefreshData, setShouldRefreshData] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [isDeletionLoading, setIsDeletionLoading] = useState(false);

  const {id} = useParams();

  const graphQLClient = GraphQLClientWithCredentials();
  const navigate = useNavigate();

  const handleOpenModal = () => {
    setVisibleModal(true);
  }

  const handleCloseModal = () => {
    setVisibleModal(false);
  }

  useEffect(() => {
    const fetchData = async () => {
      const response = await graphQLClient.request(VIEW_UNIT, {id: parseInt(id)});
      setData(response.unit);
      setIsLoaded(true);
      setShouldRefreshData(false);
    };
    fetchData();
  }, [shouldRefreshData, id]);

  if (!isLoaded) {
    return <Loading/>;
  }

  const handleDeleteObject = async (id) => {
    setIsDeletionLoading(true);
    try {
      await graphQLClient.request(DELETE_UNIT, {id: id});
      navigate('/properties/');
      setVisibleModal(false);
    } catch (error) {
      console.error('Error performing mutation:', error);
      throw error;
    }
    setIsDeletionLoading(false);
  }

  const getActions = (id) => {
    return [
      <ClickAction key={'edit'} label="Edit details" handleClickFunc={() => setCurrentPage('edit')} />,
      <ClickAction key={'delete'} label="Delete record" handleClickFunc={() => handleOpenModal(id)} />
    ];
  }

  return (
    <>
      {visibleModal && <ConfirmationModal
        title="Delete?" 
        body="This operation can't be undone." 
        cancelButtonLabel="Cancel" 
        confirmButtonLabel="Delete"
        cancelButtonFn={handleCloseModal}
        confirmButtonFn={
          async () => await handleDeleteObject(id)
        }
        isLoading={isDeletionLoading}
      />}
      <PageHeading heading={data.identifier} actions={getActions(data.id).map(action => <li key={action.key}>{action}</li>)}/>
      <UnitNavigationTabs 
        id={data.id}
        currentPage={currentPage}
        handleClick={setCurrentPage}
      /> 
      {currentPage === 'overview' && <UnitViewOverview data={data} />}
      {currentPage === 'purchase' && <UnitViewDetails unit_id={data.id} unit_data={data}/>}
      {currentPage === 'media' && <UnitViewMedia data={data}/>}
      {currentPage === 'tenants' && <UnitViewTenants unit_id={data.id}/>}
      {currentPage === 'income' && <UnitViewIncome unit_id={data.id} unit_data={data} />}
      {currentPage === 'expenses' && <UnitViewExpenses unit_id={data.id}/>}
      {currentPage === 'inventory' && <UnitViewInventory unit_id={data.id} purchase_timestamp={data.purchase_timestamp} />}
      {currentPage === 'utilities' && <UnitViewUtilities unit_id={data.id}/>}
      {currentPage === 'mortgage' && <UnitViewMortgage data={data}/>}
      {currentPage === 'listings' && <UnitViewListings unit_id={data.id}/>}
      {currentPage === 'contacts' && <UnitViewContacts unit_id={data.id}/>}
      {currentPage === 'insurance' && <UnitViewInsurance unit_id={data.id}/>}
      {currentPage === 'tasks' && <UnitViewTasks unit_id={data.id}/>}
      {currentPage === 'edit' && <UnitMutatePage id={data.id} submitCallbackFn={() => setShouldRefreshData(true)} />} 
    </>
  )
}
export default UnitViewPage
