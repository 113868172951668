import React from 'react'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
} from 'recharts';

const LineChartComponent = ({data, goal = 0, showForecast = false, showExpenses = false, title = null, className = null, showLegend = true, label="Income (€)"}) => {
  const goalDomain = goal / 10 + goal;

  return (
    <ResponsiveContainer className={className} width="80%"
      height={300}>
      {title && <h4>{title}</h4>}
      <LineChart data={data}>
        <XAxis dataKey="month"/>
        <YAxis {...(goalDomain !== 0 && {domain: [0, goalDomain]})} padding={{ top: 30, bottom: 30 }} />
        <CartesianGrid strokeDasharray="1 1"/>
        <Tooltip/>
        {showLegend && <Legend/>}
        {showForecast && <Line 
          type="monotone" 
          dataKey="forecast" 
          stroke="#999999"
          strokeDasharray={"3 3"}
          activeDot={false}
          dot={false}
          name={label}
        />}
        <Line 
          type="monotone" 
          dataKey="income" 
          stroke="green"
          activeDot={
            {r: 8}
          }
          name={label}
        />
        {showExpenses && <Line 
          type="monotone" 
          dataKey="expenses" 
          stroke="red"
          activeDot={
            {r: 8}
          }
          name="Expenses"
        />}
       {goal > 0 && <ReferenceLine y={goal} label="Monthly Goal" stroke="red" strokeDasharray="3 3" />}
      </LineChart>
    </ResponsiveContainer>
  )
}

export default LineChartComponent
