import React, {useState, useEffect} from 'react'
import RangeSlider from '../../components/common/RangeSlider.js'
import Form from '../../services/Form.js'
import PageHeading from '../../components/common/PageHeading.js'

import {RUN_PROPCHECKER} from '../../graph-ql/propchecker.js'
import ListingList from '../../components/listing/ListingList.js';
import GraphQLClientWithCredentials from '../../services/GraphQLClientWithCredentials.js';
// import { LIST_CITY } from '../../graph-ql/city';
// import { LIST_REGION } from '../../graph-ql/region';
import { LIST_NEIGHBOURHOOD } from '../../graph-ql/neighbourhood';

const PropCheckerPage = () => {

  // const [regions, setRegions] = useState([]);
  // const [cities, setCities] = useState([]);
  const [options, setSelectOptions] = useState(null);

  const graphQLClient = GraphQLClientWithCredentials();

  useEffect(() => {
    const city_id = 58;
    handleCityChange(city_id);
  }, []);

  const handleChangeForm = (e, identifier) => {
    switch (identifier.target.name) {
      // case 'country_id': handleCountryChange(identifier.target.value);
      //   break;

      // case 'region_id': handleRegionChange(identifier.target.value, []);
      //   break;

      // case 'city_id': handleCityChange(identifier.target.value, []);
      //   break;

      default:
        return;
    }
  }

  const formatResult = (input) => {
    let result = [];

    for (let i = 0; i < input.length; i++) {
      let item = input[i];
      result.push({value: item.id, label: item.name});
    }
    return result;
  }

  // const handleCountryChange = async (value) => {
  //   console.log('x');
  //   try {
  //     const data = await graphQLClient.request(LIST_REGION, {country_id: parseInt(value)});
  //     const result = formatResult(data.region_list);

  //     setSelectOptions({'region_id': result});
  //     setRegions(result);

  //     if (result.length) {
  //       await handleRegionChange(result[0].value, result);
  //     }
  //   } catch (error) {
  //     console.error('Error performing mutation:', error);
  //     throw error;
  //   }
  // }

  // const handleRegionChange = async (value, regions_) => {
  //   try {
  //     const data = await graphQLClient.request(LIST_CITY, {region_id: parseInt(value)});
  //     const result = formatResult(data.city_list);

  //     const regions__ = regions_ !== undefined && regions_.length ? regions_ : regions;

  //     setSelectOptions({'city_id': result, 'region_id': regions__});
  //     setCities(result);
  //     if (result.length) {
  //       await handleCityChange(result[0].value, regions_, result);
  //     }
  //   } catch (error) {
  //     console.error('Error performing mutation:', error);
  //     throw error;
  //   }
  // }

  const handleCityChange = async (city_id) => {
    try {
      const data = await graphQLClient.request(LIST_NEIGHBOURHOOD, {city_id: parseInt(city_id)});
      const result = formatResult(data.neighbourhood_list);

      setSelectOptions({'neighbourhood_id': result});
    } catch (error) {
      console.error('Error performing mutation:', error);
      throw error;
    }
  }

  return (
    <>
      <PageHeading heading="PropChecker"/>
      <p>
        Given
        <b> property information </b>
        (price, location, size, rooms, etc) or a
        <b> URL </b>
        to the property, PropChecker will give you information about the rentability of the investment.
      </p>
      <p>
        It will answer the question: is this deal worth it?
      </p>
      <Form formID="propchecker"
        onChangeHandler={handleChangeForm}
        options={options}
        mutation={RUN_PROPCHECKER}
        mutationName="propchecker"
        componentToRender={ListingList}
        successMessage={'Below are your results'}/>
      <RangeSlider label="Distance from unit"/>
    </>
  )
}

export default PropCheckerPage
