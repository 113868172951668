import React, {useState} from 'react'
import ServicesContainer from '../../containers/ServicesContainer.js'
import PageHeading from '../../components/common/PageHeading.js'
import {useNavigate} from "react-router-dom";
import ServiceProviderType from '../../enums/ServiceProviderType.js';
import { LIST_CITIES_WITH_SERVICE_PROVIDERS } from '../../graph-ql/city.js';
import CityFilter from '../../components/common/filter/CityFilter.js';
import EnumFilter from '../../components/common/filter/EnumFilter.js';
import { useSelector } from 'react-redux';

const ServicesPage = () => {
  const loggedInUser = useSelector((state) => state.loggedInUser);
  const [filterData, setFilterData] = useState({account_id: loggedInUser.accountID});
  const navigate = useNavigate();

 
  const addServiceHandler = () => {
    navigate('/service-provider/add')
  }

  const handleChange = (event) => {
    if (event.target.value === '0') {
      setFilterData((prevSelectedValues) => {
        const { [event.target.name]: _, ...updatedValues } = prevSelectedValues;
        updatedValues['account_id'] = loggedInUser.accountID;
        return updatedValues;
      });
    } else {
      setFilterData((prevSelectedValues) => ({
        ...prevSelectedValues,
        [event.target.name]: event.target.value,
      }));
    }
  };

  return (
    <>
      <PageHeading heading="Services"
        buttonLabel={'Add service'}
        onClickHandler={addServiceHandler}/>
      <div className="d-flex flex-row">
        <div className="p-2">
          <span>Filter</span>
        </div>
        <div className="p-2">
          <EnumFilter 
            label={"All types of services"}
            enumData={ServiceProviderType} 
            handleChange={handleChange} 
          />
        </div>
        <div className="p-2">
          <CityFilter 
            query={LIST_CITIES_WITH_SERVICE_PROVIDERS} 
            queryParams={{}}
            handleChange={handleChange}
            dataItemKey={'service_provider_list'}
          />
        </div>
      </div>
      <ServicesContainer queryParams={filterData}/>
    </>
  )
}

export default ServicesPage
