import {GraphQLClient} from 'graphql-request';
import {API_URL} from '../Config.js';
import {useNavigate} from 'react-router-dom'

const GraphQLClientWithCredentials = () => {
  const client = new GraphQLClient(API_URL, {
    credentials: 'include',
  });

  const navigate = useNavigate();

  return {
    ...client,
    request: async (query, variables = {}) => {
      try {
        return await client.request(query, variables);
      } catch (error) {
        const first_error = error.response?.errors?.[0]?.message;
        if (first_error === 'not_logged_in') {
          navigate('/logout/', {state: { isLoggedIn: false }});
          throw new Error('User is not logged in. Redirecting to logout.');
        }
        throw error;
      }
    }
  };
};

export default GraphQLClientWithCredentials
