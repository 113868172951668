import React, { useState, useEffect, useRef } from "react";
import { AUTOCOMPLETE } from '../../../../graph-ql/autocomplete';
import GraphQLClientWithCredentials from "../../../../services/GraphQLClientWithCredentials.js";

const FormAutocompleteElement = ({
  name, 
  initialValue, 
  onChangeHandler, 
  id,
  isDisabled,
  searchIdentifier,
  selectedTags,
  additionalClassName,
}) => {
  const [value, setValue] = useState(initialValue !== null ? initialValue : '');
  const [selectedLabel, setSelectedLabel] = useState(selectedTags.length > 0 && selectedTags[0]?.label ? selectedTags[0]?.label : '');

  const graphQLClient = GraphQLClientWithCredentials();

  useEffect(() => {
    setValue(initialValue !== null ? initialValue : '');
  }, [initialValue]);

  const [query, setQuery] = useState("");
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [activeIndex, setActiveIndex] = useState(-1);
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (query.trim() === "" || query.trim().length <= 3) {
      setFilteredSuggestions([]);
      return;
    }

    const fetchSuggestions = async () => {
      const response = await graphQLClient.request(AUTOCOMPLETE, {search_string: query, search_identifier: searchIdentifier});
      setFilteredSuggestions(response?.autocomplete?.data || []);
    };
    fetchSuggestions();
  }, [query]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setFilteredSuggestions([]);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      setActiveIndex((prev) => (prev < filteredSuggestions.length - 1 ? prev + 1 : prev));
    } else if (e.key === "ArrowUp") {
      setActiveIndex((prev) => (prev > 0 ? prev - 1 : prev));
    } else if (e.key === "Enter" && activeIndex >= 0) {
      handleSelect(filteredSuggestions[activeIndex]);
    }
  };

  const handleSelect = (item) => {
    setQuery('');
    setValue(item.key);
    setSelectedLabel(item.label);
    setFilteredSuggestions([]);
  };

  return (
    <div className={`form-control position-relative w-100 ${additionalClassName}`} ref={dropdownRef}>
      <input
        type="hidden"
        value={value}
        name={name}
      />
      <div className="d-flex">
        {selectedLabel && <span className="autocomplete-tag">
          {selectedLabel}
          <span className="autocomplete-tag-delete" onClick={() => {setValue(''); setSelectedLabel(''); }}>x</span>
        </span>}
        <input
          type="text"
          placeholder="Search..."
          value={query}
          onChange={(e) => {setQuery(e.target.value); if (e.target.value.trim() === '') {setValue('')}}}
          onKeyDown={handleKeyDown}
          disabled={isDisabled}
          className="form-control ms-3"
          autoComplete="off"
          id={id}
        />
      </div>
      {filteredSuggestions.length > 0 && (
        <ul className="list-group position-absolute w-100 border bg-white shadow mt-1 rounded overflow-auto" style={{ maxHeight: "160px", zIndex: 1000, marginLeft: "-12px" }}>
          {filteredSuggestions.map((suggestion, index) => (
            <li
              key={suggestion.key}
              className={`list-group-item list-group-item-action ${
                activeIndex === index ? "active" : ""
              }`}
              onMouseDown={() => handleSelect(suggestion)}
            >
              {suggestion.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default FormAutocompleteElement
