import {gql} from 'graphql-request';

// todo make this a non mutation
export const RUN_PROPCHECKER = gql `query propchecker(
  $neighbourhood_id: ID!,
  $unit_buy_price: Int,
) {
  propchecker(
    neighbourhood_id: $neighbourhood_id,
    unit_buy_price: $unit_buy_price
  ) {
      id,
      neighbourhood_id,
      asking_rent_price,
      number_of_rooms,
      annual_yield,
      taxes_fees,
      unit_buy_price,
      url
  }
}`;
