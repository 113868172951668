import React, {useState, useEffect} from 'react'
import Loading from '../../../components/common/Loading.js';
import {LIST_UNIT_INVENTORY} from '../../../graph-ql/unit_inventory.js';
import GraphQLClientWithCredentials from '../../../services/GraphQLClientWithCredentials.js';
import UnitInventoryListTable from './inventory/UnitInventoryListTable.js';
import UnitInventoryMutatePage from '../../../pages/property/inventory/UnitInventoryMutatePage.js';
import RouteActionType from '../../../enums/RouteActionType.js';
import UnitViewContentButtons from './navigation/UnitViewContentButtons.js';

const UnitViewInventory = ({unit_id, purchase_timestamp}) => {
  const [data, setData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [itemID, setItemID] = useState(null);
  const [shouldRefreshData, setShouldRefreshData] = useState(false);
  const [subpage, setSubpage] = useState('list');
  const [cancelButtonLabel, setCancelButtonLabel] = useState('Cancel');
  
  const graphQLClient = GraphQLClientWithCredentials();
  
  useEffect(() => {
    const fetchData = async () => {
      const response = await graphQLClient.request(LIST_UNIT_INVENTORY, {unit_id: unit_id});
      setData(response.unit_inventory_list);
      setIsLoaded(true);
      setShouldRefreshData(false);
    };
    fetchData();
  }, [shouldRefreshData]);

  const refreshData = () => {
    setShouldRefreshData(true);
    setIsLoaded(false);
  }

  const editHandler = (id) => {
    setItemID(id);
    setSubpage('edit');
  }

  const passedState = {unit_id: unit_id, purchase_timestamp: purchase_timestamp};

  return (
    <>
      {/* <p>Divided by rooms, view by floorplan</p> */}
      <div className="d-flex mb-3">
        <div className="form-check form-switch mt-3 flex-grow-1">
          <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"/>
          <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Include discarded items</label>
        </div>
        <div>
          <UnitViewContentButtons 
            addButtonLabel={'Add inventory item'} 
            viewButtonLabel={'View inventory'}
            cancelButtonLabel={cancelButtonLabel} 
            // todo finish this
            // secondaryButtons={[{label: 'Download PDF'}, {label: 'Email PDF'}]}
            secondaryButtons={[]}
            subpage={subpage} 
            setSubpageFn={setSubpage}
          />
        </div>
        </div>
        {subpage === 'list' && isLoaded && <UnitInventoryListTable 
          data={data} 
          editHandlerFn={editHandler}
          addHandlerFn={() => setSubpage('add')}
        />}
        {subpage === 'list' && !isLoaded && <Loading />}
        {subpage === 'add' && <UnitInventoryMutatePage 
          formSubmitCallbackFn={refreshData} 
          action={RouteActionType.add} 
          extraData={{'unit': passedState}} 
        />}
        {subpage === 'edit' && <UnitInventoryMutatePage 
          formSubmitCallbackFn={() => {
            refreshData(); 
            setCancelButtonLabel('View inventory');
          }} 
          action={RouteActionType.edit} 
          extraData={{'unit': passedState}} 
          id={itemID}
        />}
    </>
  )
}

export default UnitViewInventory
