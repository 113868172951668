import {gql} from 'graphql-request';

export const LIST_CITY = gql `query city_list($region_id: ID){
  city_list
    (region_id: $region_id){
    id, 
		region {
			id, 
			name
		}, 
		name
  }
}`;

export const LIST_CITIES_WITH_UNITS = gql `query unit_list($account_id: ID!){
  unit_list(account_id: $account_id){
    city {
			id, 
			name
		}, 
  }
}`;

export const LIST_CITIES_WITH_NOTARIES = gql `query notary_list{
  notary_list{
    city {
			id, 
			name
		}, 
  }
}`;

export const LIST_CITIES_WITH_REAL_ESTATE_AGENTS = gql `query real_estate_agent_list{
  real_estate_agent_list{
    city {
			id, 
			name
		}, 
  }
}`;

export const LIST_CITIES_WITH_SERVICE_PROVIDERS = gql `query service_provider_list{
  service_provider_list{
    city {
			id, 
			name
		}, 
  }
}`;

export const VIEW_CITY = gql `query view_city(
  $id: ID!
){
  city(id: $id) {
    id, 
		region {
			id, 
			name
		}, 
		name
  }
}`

// START MANUAL SECTION



// END MANUAL SECTION