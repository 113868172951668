import React, {useState, useEffect} from 'react'
import PageHeading from '../components/common/PageHeading.js';
import MonthlyIncomeCalendar from '../components/income/MonthlyIncomeCalendar.js';
import IncomeNavigationTabs from '../components/income/IncomeNavigationTabs.js';
import GraphQLClientWithCredentials from '../services/GraphQLClientWithCredentials.js';
import Loading from '../components/common/Loading.js';
import { GET_STATS, GET_INCOME } from '../graph-ql/dashboard.js';
// import IncomeOverview from '../components/income/IncomeOverview.js';
import { convertToIncomeData, getIncomeForCurrentMonthByUnit } from '../services/Income.js';
import FinancialInsights from '../components/income/FinancialInsights.js';
import { useSelector } from 'react-redux';
import { gql } from 'graphql-request';

const FinancialsPage = () => {
  const [currentPage, setCurrentPage] = useState('insights');
  const [data, setData] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);

  const [currentMonthUnitIncome, setCurrentMonthUnitIncome] = useState({});
  // const [incomeByMonth, setIncomeByMonth] = useState({});
  const [stats, setStats] = useState(null);

  const loggedInUser = useSelector((state) => state.loggedInUser);

  const graphQLClient = GraphQLClientWithCredentials();

  useEffect(() => {

    const fetchUnitIDs = async () => {
      const LIST_UNIT_QUERY = gql `query unit_list($account_id: ID!){
        unit_list(account_id: $account_id) {
          id
        }
      }`;
      const response = await graphQLClient.request(LIST_UNIT_QUERY, {account_id: loggedInUser.accountID});
      const units = response.unit_list;
      const unit_ids = units.map(({ id }) => id);
      fetchData(unit_ids);
    }

    const fetchData = async (unit_ids) => {
      if (!unit_ids.length) {
        setIsLoaded(true);
        return;
      }
      const response = await graphQLClient.request(GET_INCOME, {unit_ids: unit_ids});
      const stats_response = await graphQLClient.request(GET_STATS);

      setStats(stats_response.dashboard);

      setData(convertToIncomeData(response.unit_income_list, stats_response.dashboard.monthly_income));
      // setIncomeByMonth(getIncomeByMonth(response.unit_income_list));
      setCurrentMonthUnitIncome(getIncomeForCurrentMonthByUnit(response.unit_income_list));
      setIsLoaded(true);
    };

    fetchUnitIDs();
  }, []);

  if (!isLoaded) {
    return <Loading/>;
  }

  return (
    <>
      <PageHeading heading="Financials"/>
      <IncomeNavigationTabs
        currentPage={currentPage}
        handleClick={setCurrentPage}/>
      {/* {incomeByMonth && currentPage === 'overview' && <IncomeOverview data={incomeByMonth} />} */}
      {currentPage === 'calendar' && <MonthlyIncomeCalendar />}
      {currentPage === 'insights' && <FinancialInsights data={data} goal={stats && stats.monthly_income_goal} currentMonthUnitIncome={currentMonthUnitIncome} />}
    </>

  )
}

export default FinancialsPage
