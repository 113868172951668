import React from 'react';
import '../../../styles/circular_progress_bar.css';

const CircularProgressBar = ({ currentValue, targetValue = 0, label, targetValueLabel, currentValueDisplay }) => {
  const radius = 50;
  const strokeWidth = 5;
  const circumference = 2 * Math.PI * radius;
  const progress = targetValue === 0 ? 0 : (currentValue / targetValue) * 100;
  const offset = circumference - (progress / 100) * circumference;

  return (
    <div className="progress-circle-container">
      <svg className="progress-circle" height="120" width="120">
        <circle
          className="progress-circle-bg"
          stroke="#e6e6e6"
          strokeWidth={strokeWidth}
          fill="transparent"
          r={radius}
          cx="60"
          cy="60"
        />
        <circle
          className="progress-circle-bar"
          stroke="#4caf50"
          strokeWidth={strokeWidth}
          fill="transparent"
          r={radius}
          cx="60"
          cy="60"
          style={{
            strokeDasharray: circumference,
            strokeDashoffset: offset
          }}
        />
      </svg>
      <div className="progress-circle-content">
        <div className="progress-value">{currentValueDisplay}</div>
        <div className="progress-label">{label}</div>
      </div>
      {targetValueLabel && <div className="progress-goal">
        <span>{targetValueLabel}</span>
      </div>}
    </div>
  );
};

export default CircularProgressBar;
