import React, {useState} from 'react'
import ConfirmationModal from '../../../common/ConfirmationModal.js';
import NoContentAlert from '../../../common/NoContentAlert.js';
import { threeDotMenuIcon } from '../../../../assets/svg/common.js';
import GraphQLClientWithCredentials from '../../../../services/GraphQLClientWithCredentials.js';
import { DELETE_UNIT_UTILITY } from '../../../../graph-ql/unit_utility.js';

const UnitUtilityViewTable = ({data, addHandlerFn, editHandlerFn}) => {
  const [hiddenComponents, setHiddenComponents] = useState([]);
  const [visibleModal, setVisibleModal] = useState(null);
  const [isDeletionLoading, setIsDeletionLoading] = useState(false);

  const graphQLClient = GraphQLClientWithCredentials();

  const handleOpenModal = (id) => {
    setVisibleModal(id);
  }

  const handleCloseModal = () => {
    setVisibleModal(null);
  }

  const handleDeleteObject = async (id) => {
    setIsDeletionLoading(true);
    try {
      await graphQLClient.request(DELETE_UNIT_UTILITY, {id: id});
      setHiddenComponents(prevHiddenComponents => [
        ...prevHiddenComponents,
        id
      ]);
      setVisibleModal(null);
    } catch (error) {
      console.error('Error performing mutation:', error);
      throw error;
    }
    setIsDeletionLoading(false);
  }
  
  const actions = [
    {
      key: 'view-utility-contract',
      label: 'View utility contract',
      handleClick: () => {
        console.log('view contract');
      }
    },
    {
      key: 'delete-utility',
      label: 'Delete record',
      handleClick: (id) => {
        handleOpenModal(id);
      }
    },
  ]

  if (!data.length) {
    return <NoContentAlert 
      messageStringStart='There are no utilities!'
      messageStringEnd='to this property'
      callToActionString='Add your first utility'
      callToActionFn={addHandlerFn}
    />
  }

  return <table className="table table-hover table-striped">
    <thead>
      <tr>
        <th scope="col">Type</th>
        <th scope="col">Name</th>
        <th scope="col">Platform URL</th>
        <th scope="col">Phone</th>
        <th scope="col">Actions</th>
      </tr>
    </thead>
    <tbody>{
      data.map(utility => {
        return !hiddenComponents.includes(utility.id) && <tr key={
            utility.id
          }>
          {visibleModal === utility.id && 
            <ConfirmationModal
              key={`confirmation-modal-${utility.id}`}
              id={utility.id}
              title="Delete?" 
              body="This operation can't be undone." 
              cancelButtonLabel="Cancel" 
              confirmButtonLabel="Delete"
              cancelButtonFn={handleCloseModal}
              confirmButtonFn={
                async () => await handleDeleteObject(utility.id)
              }
              isLoading={isDeletionLoading}
            />
          }
          <td>{
            utility.utility.type_display
          }</td>
          <td>{
            utility.utility.name
          }</td>
          <td>{
            utility.utility.platform_url
          }</td>
          <td>{
            utility.utility.phone
          }</td>
          <td>
            <div className="nav-item dropdown position-relative">
              <a
                className="position-absolute top-100 start-50 translate-middle mt-1"
                data-bs-toggle="dropdown"
                role="button"
                aria-expanded="false">
                {threeDotMenuIcon}
              </a>
              <ul className="dropdown-menu">
                {
                actions.map(action => {
                  return <li key={
                    action.key
                  }>
                    <a className="dropdown-item"
                      onClick={
                        () => action.handleClick(utility.id)
                    }>
                      {
                      action.label
                    }</a>
                  </li>
              })
              } 
              </ul>
            </div>
          </td>
        </tr>
    })
    }</tbody>
  </table>
}

export default UnitUtilityViewTable