import React from 'react';
import UnitIdentifier from './fragments/UnitIdentifier.js';
import { locationIcon, redTriangleIcon, percentIcon, currencyExchangeIcon } from '../../assets/svg/icons.js';
import CardTitleWithThreeDotMenu from '../common/card/CardTitleWithThreeDotMenu.js';
import { useNavigate } from 'react-router-dom';
import CardContentItem from '../common/card/CardContentItem.js';
import { formatPriceInEUR, formatPercentage } from '../../services/Format.js';

const UnitCard = (props) => {
  const navigate = useNavigate();
  const redirectToEdit = (id) => {
    navigate('/property/edit/' + id);
  }

  const title = <UnitIdentifier identifier={props.identifier} id={props.id}/>;
  
  const actions = [
    {
      key: 'edit',
      label: 'Edit details',
      handleClick: (id) => {
        redirectToEdit(id);
      }
    }, {
      key: 'delete',
      label: 'Delete record',
      handleClick: (id) => {
        props.handleOpenModal(id);
      }
    },
  ];
  return (
    <div className="card mb-3">
      <div className="row g-0">
        {props.identifier_image_url && <div className="col-md-4">
          <img src={props.identifier_image_url}
            className="img-fluid rounded-start"
            style={{maxWidth: '300px'}}
            alt="..."/>
        </div>}
        <div className="col-md-8">
          <div className="card-body">
            <CardTitleWithThreeDotMenu id={props.id} title={title} actions={actions} />
            <CardContentItem label={locationIcon} text={props.city.name} />
            <CardContentItem label={currencyExchangeIcon} text={`Monthly income: ${formatPriceInEUR(props.monthly_income)}`} />
            <CardContentItem label={percentIcon} text={`Annual yield: ${formatPercentage(props.expected_anual_yield)}`} />
            <CardContentItem label={percentIcon} text={`Monthly income weight: ${formatPercentage(props.monthly_income_weight)}`} />
            {props.current_number_of_tenants === 0 && <CardContentItem label={redTriangleIcon} text={<i className="small-notice">This property is currently vacant</i>} />}
          </div>
        </div>
      </div>
    </div>
  )
}

export default UnitCard
