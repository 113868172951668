import {gql} from 'graphql-request';

export const ADD_NOTIFICATION = gql `mutation notification_create(
  $account_id: ID!, 
	$unit_task_id: ID, 
	$title: String!, 
	$content: String!, 
	$extra_data: String, 
	$status: String, 
	$type: String!
) {
  notification_create(
    account_id: $account_id, 
		unit_task_id: $task_id, 
		title: $title, 
		content: $content, 
		extra_data: $extra_data, 
		status: $status, 
		type: $type
  ) {
    data {
      id,
    }
    result,
    exceptions {
      property,
      failed_rules {
        message
      }
    }
  }
}`;

export const UPDATE_NOTIFICATION = gql `mutation notification_update(
  $id: ID!,
  $account_id: ID!, 
	$unit_task_id: ID, 
	$title: String!, 
	$content: String!, 
	$extra_data: String, 
	$status: String, 
	$type: String!
) {
  notification_update(
    id: $id,
    account_id: $account_id, 
		unit_task_id: $task_id, 
		title: $title, 
		content: $content, 
		extra_data: $extra_data, 
		status: $status, 
		type: $type
  ) {
    data {
      id
    }
    result,
    exceptions {
      property,
      failed_rules {
        message
      }
    }
  }
}`;

export const DELETE_NOTIFICATION = gql `mutation notification_delete(
  $id: ID!
) {
  notification_delete(id: $id) {
    data {
      id
    },
    result,
    exceptions {
        property,
        failed_rules {
            message
        }
    }
  }
}`

export const LIST_NOTIFICATION = gql `query notification_list($account_id: ID!){
  notification_list(account_id: $account_id)
    {
    id, 
		account {
			id, 
			name, 
			creation_timestamp
		}, 
		unit_task {
			id, 
			title, 
			status, 
			type, 
			urgency, 
			due_date
		}, 
		title, 
		content, 
		extra_data, 
		status, 
		type
  }
}`;

export const VIEW_NOTIFICATION = gql `query view_notification(
  $id: ID!
){
  notification(id: $id) {
    id, 
		account {
			id, 
			name, 
			creation_timestamp
		}, 
		task {
			id, 
			title, 
			status, 
			type, 
			urgency, 
			due_date
		}, 
		title, 
		content, 
		extra_data, 
		status, 
		type
  }
}`

// START MANUAL SECTION



// END MANUAL SECTION