import React, {useState, useEffect} from 'react'
import GraphQLClientWithCredentials from '../../services/GraphQLClientWithCredentials.js';
import Loading from '../common/Loading.js';
import { formatPriceInEUR, formatMonths } from '../../services/Format';
import { OverlayTrigger } from 'react-bootstrap';
import { Tooltip } from 'react-bootstrap';
import {gql} from 'graphql-request';
import { Link } from 'react-router-dom';

const GQL = gql `query unit_tenant_list(
	$unit_id: ID, 
	$rent_due_day: Int,
  $unit_ids: [ID]
) {
  unit_tenant_list(
		unit_id: $unit_id,
		rent_due_day: $rent_due_day,
    unit_ids: $unit_ids
	) {
    id, 
		tenant {
			id, 
			name, 
			email, 
			phone, 
			profile_type, 
			profile_type_display,
			length_of_stay_in_months,
			remaining_contract_period_in_months,
			forecasted_remaining_income,
			current_tenancy_contract_url,
			government_document_url,
		}, 
    unit {
      identifier,
      id
    },
		rent_price
  }
}`;

const IncomeCalendarDetails = ({selectedDate, unitIDs}) => {
  const graphQLClient = GraphQLClientWithCredentials();
  const [data, setData] = useState({});
  const [expectedIncome, setExpectedIncome] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (selectedDate === null) {
      return;
    }

    setIsLoaded(false);
    const fetchData = async () => {
      const response = await graphQLClient.request(GQL, {rent_due_day: selectedDate.day, unit_ids: unitIDs});
      setData(response.unit_tenant_list);

      let expectedIncome = 0;
      response.unit_tenant_list.map(item => {
        expectedIncome += item.rent_price;
      })
      setExpectedIncome(expectedIncome);
      setIsLoaded(true);
    };
    fetchData();
  }, [selectedDate]);

  if (selectedDate === null) {
    return <></>;
  }

  if (!isLoaded) {
    return <Loading/>;
  }

  const titlePrefix = (new Date()) > selectedDate ? 'Received income on' : 'Expected income on';

  return (
    <>
      <h3>{titlePrefix} {selectedDate.day} {selectedDate.month.name} {selectedDate.year}: {formatPriceInEUR(expectedIncome)}</h3>
      <table className="table table-hover table-striped w-100">
        <thead>
          <tr>
            <th></th>
            <th scope="col">Property</th>
            <th scope="col">Tenant</th>
            <th scope="col">Income</th>
          </tr>
        </thead>
        <tbody>{
          data.map(tenant => {
            return (<><tr key={tenant.id}>
                <td>
                  <a className="btn btn-secondary" data-bs-toggle="collapse" href={`#income-details-${tenant.id}`} role="button" aria-expanded="false" aria-controls={`#income-details-${tenant.id}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-journal-arrow-down" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M8 5a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5A.5.5 0 0 1 8 5"/>
                      <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2"/>
                      <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1z"/>
                    </svg>
                  </a>
                </td>
                <td>{
                  <Link 
                  to={`/property/view/${tenant.unit.id}`}
                  state={{id: tenant.unit.id}}>
                  {tenant.unit.identifier}
                </Link>
                }</td>
                <td>{
                  tenant.tenant.name
                }</td>
                <td>{
                  formatPriceInEUR(tenant.rent_price)
                }</td>
              </tr>
              <tr className="collapse" key={`income-details-${tenant.id}`} id={`income-details-${tenant.id}`}>
                <td>
                  <div>
                    <h3>Rent history</h3>
                    <OverlayTrigger key={`income-details-1-${tenant.id}`} placement="top"
                      overlay={<Tooltip id={`tooltip-top-1`}>
                      Rent paid on time</Tooltip>}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#198753" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                      </svg>
                    </OverlayTrigger>
              
                    <OverlayTrigger key={`income-details-2-${tenant.id}`} placement="top"
                      overlay={<Tooltip id={`tooltip-top-2`}>
                      Rent paid on time</Tooltip>}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#198753" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                      </svg>
                    </OverlayTrigger>
                    <OverlayTrigger key={`income-details-3-${tenant.id}`} placement="top"
                      overlay={<Tooltip id={`tooltip-top-3`}>
                      Rent paid on time</Tooltip>}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#198753" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                      </svg>
                    </OverlayTrigger>
                    <OverlayTrigger key={`income-details-4-${tenant.id}`} placement="top"
                      overlay={<Tooltip id={`tooltip-top-4`}>
                      Rent paid on time</Tooltip>}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#198753" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                      </svg>
                    </OverlayTrigger>
                    <OverlayTrigger key={`income-details-5-${tenant.id}`} placement="top"
                      overlay={<Tooltip id={`tooltip-top-5`}>
                      Rent paid on time</Tooltip>}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#198753" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                      </svg>
                    </OverlayTrigger>
                    
                    <OverlayTrigger key={`income-details-6-${tenant.id}`} placement="top"
                      overlay={<Tooltip id={`tooltip-top-6`}>
                      Rent due date missed</Tooltip>}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#DC3444" className="bi bi-dash-circle-fill" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1z"/>
                    </svg>
                    </OverlayTrigger>
                    
                    <OverlayTrigger key={`income-details-7-${tenant.id}`} placement="top"
                      overlay={<Tooltip id={`tooltip-top-7`}>
                      Rent paid on time</Tooltip>}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#198753" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                      </svg>
                    </OverlayTrigger>
                  </div>
                  <p className="mt-3">Forecasted income: <b>{formatPriceInEUR(tenant.tenant.forecasted_remaining_income)}</b></p>
                </td>
                <td>
                  <p>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person" viewBox="0 0 16 16">
                      <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z"/>
                    </svg>
                    <span>{tenant.tenant.profile_type_display}</span>
                  </p>
                  <p>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clock" viewBox="0 0 16 16">
                      <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z"/>
                      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0"/>
                    </svg>
                    <span>{formatMonths(tenant.tenant.length_of_stay_in_months)}</span>
                  </p>
                  <p>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-hourglass-split" viewBox="0 0 16 16">
                      <path d="M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48zm1 0v3.17c2.134.181 3 1.48 3 1.48a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351z"/>
                    </svg>
                    <span>{formatMonths(tenant.tenant.remaining_contract_period_in_months)}</span>
                  </p>
                </td>
                <td>
                  <p>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope-at" viewBox="0 0 16 16">
                      <path d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2zm3.708 6.208L1 11.105V5.383zM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2z"/>
                      <path d="M14.247 14.269c1.01 0 1.587-.857 1.587-2.025v-.21C15.834 10.43 14.64 9 12.52 9h-.035C10.42 9 9 10.36 9 12.432v.214C9 14.82 10.438 16 12.358 16h.044c.594 0 1.018-.074 1.237-.175v-.73c-.245.11-.673.18-1.18.18h-.044c-1.334 0-2.571-.788-2.571-2.655v-.157c0-1.657 1.058-2.724 2.64-2.724h.04c1.535 0 2.484 1.05 2.484 2.326v.118c0 .975-.324 1.39-.639 1.39-.232 0-.41-.148-.41-.42v-2.19h-.906v.569h-.03c-.084-.298-.368-.63-.954-.63-.778 0-1.259.555-1.259 1.4v.528c0 .892.49 1.434 1.26 1.434.471 0 .896-.227 1.014-.643h.043c.118.42.617.648 1.12.648m-2.453-1.588v-.227c0-.546.227-.791.573-.791.297 0 .572.192.572.708v.367c0 .573-.253.744-.564.744-.354 0-.581-.215-.581-.8Z"/>
                    </svg>
                    <a target="_blank" href={`mailto:${tenant.tenant.email}`}>{tenant.tenant.email}</a>
                  </p>
                  <p>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-whatsapp" viewBox="0 0 16 16">
                      <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232"/>
                    </svg>
                    <a target="_blank" href={`http://wa.me/${tenant.tenant.phone}`}>{tenant.tenant.phone}</a>
                  </p>
                </td>
                <td>
                  <p>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-pdf" viewBox="0 0 16 16">
                      <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z"/>
                      <path d="M4.603 14.087a.8.8 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.7 7.7 0 0 1 1.482-.645 20 20 0 0 0 1.062-2.227 7.3 7.3 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a11 11 0 0 0 .98 1.686 5.8 5.8 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.86.86 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.7 5.7 0 0 1-.911-.95 11.7 11.7 0 0 0-1.997.406 11.3 11.3 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.8.8 0 0 1-.58.029m1.379-1.901q-.25.115-.459.238c-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361q.016.032.026.044l.035-.012c.137-.056.355-.235.635-.572a8 8 0 0 0 .45-.606m1.64-1.33a13 13 0 0 1 1.01-.193 12 12 0 0 1-.51-.858 21 21 0 0 1-.5 1.05zm2.446.45q.226.245.435.41c.24.19.407.253.498.256a.1.1 0 0 0 .07-.015.3.3 0 0 0 .094-.125.44.44 0 0 0 .059-.2.1.1 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a4 4 0 0 0-.612-.053zM8.078 7.8a7 7 0 0 0 .2-.828q.046-.282.038-.465a.6.6 0 0 0-.032-.198.5.5 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822q.036.167.09.346z"/>
                    </svg>
                    {tenant.tenant.current_tenancy_contract_url ? <a target="_blank" href={tenant.tenant.current_tenancy_contract_url}>View tenancy contract</a> : 'No tenancy contract'}
                  </p>
                  <p>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-person" viewBox="0 0 16 16">
                      <path d="M11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
                      <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2v9.255S12 12 8 12s-5 1.755-5 1.755V2a1 1 0 0 1 1-1h5.5z"/>
                    </svg>
                    {tenant.tenant.government_document_url ? <a target="_blank" href={tenant.tenant.government_document_url}>View government document</a> : 'No government document'}
                  </p>
                </td>
              </tr></>)
        })
        }</tbody>
      </table>
    </>
  )
}

export default IncomeCalendarDetails