import React, {useState} from 'react'
import PageHeading from '../../components/common/PageHeading.js'
import Form from '../../services/Form.js'
import {USER_LOGIN} from '../../graph-ql/auth.js'
import store from '../../redux/store';
import {useNavigate} from 'react-router-dom'
import {sleep} from '../../services/Utils.js';

const LoginPage = () => {
  const [userName, setUserName] = useState(null);
  const navigate = useNavigate();

  const submitHandler = async (data) => {
    if (data && data.result) {
      setUserName(data.user_name);
      store.dispatch({
        type: 'actionLogin',
        payload: {
          userName: data.user_name,
          clearanceLevel: data.clearance_level,
          id: data.user_id,
          accountID: data.account_id,
        }
      });
      await sleep(1500);
      navigate('/');
      window.location.reload();
    }
  };

  return (
    <>
      <PageHeading heading="Login"/>
      <Form formID="user-login"
        mutation={USER_LOGIN}
        mutationName="auth"
        formSubmitCallback={submitHandler}
        successMessage={
          'Welcome back ' + userName
        }/>
    </>
  )
}

export default LoginPage
