import React, { useState, useEffect } from 'react'
import PageHeading from '../../components/common/PageHeading.js'
import {useNavigate} from "react-router-dom";
import GraphQLClientWithCredentials from '../../services/GraphQLClientWithCredentials.js';
import { LIST_REAL_ESTATE_AGENT } from '../../graph-ql/real_estate_agent.js';
import RealEstateAgentList from '../../components/real-estate-agent/RealEstateAgentList.js';
import { LIST_CITIES_WITH_REAL_ESTATE_AGENTS } from '../../graph-ql/city.js';
import CityFilter from '../../components/common/filter/CityFilter.js';
import { useSelector } from 'react-redux';

const RealEstateAgentsPage = () => {
  const [filterData, setFilterData] = useState({});
  const [data, setData] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const navigate = useNavigate();

  const addHandler = () => {
    navigate('/real-estate-agent/add')
  }

  const loggedInUser = useSelector((state) => state.loggedInUser);
  const graphQLClient = GraphQLClientWithCredentials();

  const handleChange = (event) => {
    if (event.target.value === '0') {
      setFilterData((prevSelectedValues) => {
        const { [event.target.name]: _, ...updatedValues } = prevSelectedValues;
        return updatedValues;
      });
    } else {
      setFilterData((prevSelectedValues) => ({
        ...prevSelectedValues,
        [event.target.name]: event.target.value,
      }));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoaded(false);
      filterData['account_id'] = loggedInUser.accountID;
      const response = await graphQLClient.request(LIST_REAL_ESTATE_AGENT, filterData);
      setData(response.real_estate_agent_list);
      setIsLoaded(true);
    };
    fetchData();

  }, [filterData]);

  return (
    <>
      <PageHeading heading="My real estate agents"
        buttonLabel={'Add real estate agent'}
        onClickHandler={addHandler}/>
      <div className="d-flex flex-row">
        <div className="p-2">
          <span>Filter</span>
        </div>
        <div className="p-2">
          <CityFilter 
            query={LIST_CITIES_WITH_REAL_ESTATE_AGENTS} 
            queryParams={{}}
            handleChange={handleChange}
            dataItemKey={'real_estate_agent_list'}
          />
        </div>
      </div>
      <RealEstateAgentList data={data} loading={!isLoaded} />
    </>
  )
}

export default RealEstateAgentsPage
