import React, {useEffect} from 'react'
import {Link} from 'react-router-dom';
import {useNavigate} from "react-router-dom";

const TopNavigationItem = ({
  requiresLogin,
  label,
  path,
  isLoggedIn,
  onCompleteLoading,
  hasBadging,
  handleClick,
  title,
}) => {
  useEffect(() => {
    if (typeof onCompleteLoading === 'function') {
      onCompleteLoading();
    }
  }, []);

  const navigate = useNavigate();

  if (requiresLogin && !isLoggedIn || (!requiresLogin && isLoggedIn)) {
    return;
  }

  const badge = <span className="position-absolute translate-middle bg-danger border border-light rounded-circle notification-badge">
    <span className="visually-hidden">New alerts</span>
  </span>;

  const handleClickEvent = (e) => {
    e.preventDefault();

    if (typeof handleClick === 'function') {
      handleClick();
    } else {
      navigate('/' + path);
    }
  }

  return (
    <li className="nav-item m-1">
      <Link className="nav-link" title={title} style={{padding: '6px'}}
        onClick={handleClickEvent}>
        {label}
        {
        hasBadging && badge
      }</Link>
    </li>
  )
}

export default TopNavigationItem
