import React from 'react';
import DashboardCard from '../dashboard/DashboardCard.js';
import Loading from '../common/Loading.js';
import {formatPriceInEUR, formatPercentage} from '../../services/Format.js'

const ReportsOverview = ({data, error, loading}) => {
  if (loading) {
    return <Loading/>
  }

  return <>
    <h2>Account overview</h2>
    <div className="container text-center">
      <div className="row mt-4">
        <div className="col">
          <DashboardCard 
            title="Monthly income" 
            value={formatPriceInEUR(data.dashboard.monthly_income)}
            tooltipText="Total monthly income generated from all the properties in your portfolio"
          />
        </div>
        <div className="col">
          <DashboardCard 
            title="Annual yield" 
            value={formatPercentage(data.dashboard.annual_yield)}
            tooltipText="Lorem ipsum todo"
          />
        </div>
        <div className="col">
          <DashboardCard 
            title="Number of properties" 
            value={data.dashboard.unit_count}
            tooltipText="Number of properties currently in your portfolio"
          />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col">
          <DashboardCard 
            title="Number of tenants" 
            value={data.dashboard.active_tenants_count}
            tooltipText="Lorem ipsum todo"
          />
        </div>
        <div className="col">
          <DashboardCard 
            title="Occupancy rate" 
            value={formatPercentage(data.dashboard.occupancy_rate)}
            tooltipText="Lorem ipsum todo"
          />
        </div>
        <div className="col">
          <DashboardCard 
            title="Total investment" 
            value={formatPriceInEUR(data.dashboard.total_investment)}
            tooltipText="Lorem ipsum todo"
          />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col">
          {
          <DashboardCard title="Year-to-date income" value={
            formatPriceInEUR(data.dashboard.ytd_income)
          }
          tooltipText="Lorem ipsum todo" />
        }</div>
        <div className="col">
          <DashboardCard 
            title="Lifetime expenses" 
            value={formatPriceInEUR(data.dashboard.lifetime_expenses)}
            tooltipText="Lorem ipsum todo"
          />
        </div>
        <div className="col">
          <DashboardCard 
            title="Year-to-date expenses" 
            value={formatPriceInEUR(data.dashboard.ytd_expenses)}
            tooltipText="Lorem ipsum todo"
          />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col">
          <DashboardCard 
            title="Lifetime net profit" 
            value={formatPriceInEUR(data.dashboard.lifetime_net_profit)}
            tooltipText="Lorem ipsum todo"
          />
        </div>
        <div className="col">
          <DashboardCard 
            title="Year-to-date net profit" 
            value={formatPriceInEUR(data.dashboard.ytd_net_profit)}
            tooltipText="Lorem ipsum todo"
          />
        </div>
        <div className="col">
          <DashboardCard 
            title="Lifetime income" 
            value={formatPriceInEUR(data.dashboard.lifetime_income)}
            tooltipText="Lorem ipsum todo"
          />
        </div>
      </div>
      {/* todo finish this */}
      {/* <div className="row mt-4">
        <div className="col">
          <DashboardCard 
            title="Portfolio value" 
            value={'TODO current portfolio value'}
            tooltipText="Lorem ipsum todo"
          />
        </div>
      </div> */}
    </div>
  </>
};

export default ReportsOverview;
