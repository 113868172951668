import React from 'react'
import { useFileDownload } from '../../../hooks/useFileDownload.js';

const DownloadFileAction = ({data, label = 'Download'}) => {
  const downloadFile = useFileDownload();

  const handleClick = (data) => {
    downloadFile(data);
  ;}

  return (
    <a 
      className="dropdown-item"
      onClick={() => handleClick(data)}
      >
      <span>{label}</span>
    </a>
  )
};

export default DownloadFileAction;
