import React from 'react';
import { useNavigate } from 'react-router-dom';
import { formatURL } from '../../services/Format';
import { houseIcon, locationIcon, phoneIcon, questionMarkIcon, websiteURLIcon } from '../../assets/svg/icons.js';
import CardTitleWithThreeDotMenu from '../common/card/CardTitleWithThreeDotMenu.js';
import CardContentItem from '../common/card/CardContentItem.js';
import UnitIdentifier from '../unit/fragments/UnitIdentifier';

const UtilityCard = (props) => {
  const navigate = useNavigate();
  const redirectToEdit = (id) => {
    navigate('/utility/edit/' + id);
  }

  const actions = [
    {
      key: 'edit',
      label: 'Edit details',
      handleClick: (id) => {
        redirectToEdit(id);
      }
    }, {
      key: 'delete',
      label: 'Delete record',
      handleClick: (id) => {
        props.handleOpenModal(id);
      }
    },
  ];

  const unitLinks = props.units ? props.units.map(unit => <UnitIdentifier key={unit.id} id={unit.id} identifier={unit.identifier} />) : <i>none</i>;

  return (
    <>
      <div className="card mb-3">
        <div className="card-body">
          <CardTitleWithThreeDotMenu id={props.id} title={props.name} actions={actions} />
          <div className="d-flex">
            <div className="w-30 me-5">
              {props.image_url && <img src={props.image_url} />}
            </div>
            <div className="w-50">
              <CardContentItem label={questionMarkIcon} text={props.type_display} />
              <CardContentItem label={locationIcon} text={props.country.name} />
              <CardContentItem label={websiteURLIcon} text={props.platform_url && <a href={formatURL(props.platform_url)} target="_blank">{props.platform_url}</a>} />
              <CardContentItem label={phoneIcon} text={props.phone} />
            </div>
            <div className="w-20">
              <CardContentItem label={houseIcon} text={unitLinks} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UtilityCard
