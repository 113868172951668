import {gql} from 'graphql-request';

export const DELETE_UNIT_EXPENSE = gql `mutation unit_expense_delete(
  $id: ID!
) {
  unit_expense_delete(id: $id) {
    data {
      id
    },
    result,
    exceptions {
        property,
        failed_rules {
            message
        }
    }
  }
}`

export const LIST_UNIT_EXPENSE = gql `query unit_expense_list($unit_id: ID){
  unit_expense_list
    (unit_id: $unit_id){
    id,
		type, 
		type_display,
		billing_timestamp, 
		value, 
		description
  }
}`;

export const VIEW_UNIT_EXPENSE = gql `query view_unit_expense(
  $id: ID!
){
  unit_expense(id: $id) {
    id, 
		unit {
			id, 
			identifier, 
			address,  
			identifier_image_url, 
			monthly_income, 
			monthly_income_weight, 
			time_in_portfolio_in_years, 
			expected_anual_yield, 
			lifetime_income, 
			ytd_income, 
			lifetime_expenses, 
			ytd_expenses, 
			lifetime_net_profit, 
			ytd_net_profit, 
			current_number_of_tenants
		}, 
		type, 
		billing_timestamp, 
		value, 
		description
  }
}`

// START MANUAL SECTION



// END MANUAL SECTION