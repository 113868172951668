import React, {useState, useEffect} from 'react'
import {formatPriceInEUR, formatPercentage, formatMonths} from '../../../services/Format.js';
import GraphQLClientWithCredentials from '../../../services/GraphQLClientWithCredentials.js';
import FinancialInsights from '../../income/FinancialInsights.js';
import { gql } from 'graphql-request';
import { months } from '../../../services/Income.js';
import TaskList from '../../task/TaskList.js';
import CircularProgressBar from '../../common/chart/CircularProgressBar.js';
import InfoBox from '../../common/InfoBox.js';
import Loading from '../../common/Loading.js';

const UnitViewOverview = ({data}) => {
  const [incomeData, setIncomeData] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [isTasksLoaded, setIsTasksLoaded] = useState(false);

  const graphQLClient = GraphQLClientWithCredentials();

  const fetchTasks = async () => {

    const LIST_TASK = gql `query unit_task_list(
      $unit_id: ID,
      $status: String,
    ) {
      unit_task_list(unit_id: $unit_id, status: $status) {
        id, 
        title, 
        status, 
        status_display,
        type, 
        urgency, 
        urgency_display,
        due_date,
        type_display,
        urgency_display,
        status_display
      }
    }`;

    try {
      const response = await graphQLClient.request(LIST_TASK, {status: "todo", unit_id: data.id});
      setIsTasksLoaded(true);
      if (response.unit_task_list && response.unit_task_list.length) {
        setTasks(response.unit_task_list);
      }
    } catch (err) {}
  };

  const fetchIncomeData = async () => {

    const INCOME_QUERY = gql `query view_unit(
      $id: ID!, 
    ) {
      unit(
        id: $id,
      ) {
        last_year_monthly_income,
        last_year_monthly_expenses,
      }
    }`;

    try {
      const response = await graphQLClient.request(INCOME_QUERY, {id: data.id});
      if (response.unit) {
        let data_ = [];
        for (let i = 0; i < 12; i++) {
          data_[i] = {
            month: months[i],
            income: response.unit.last_year_monthly_income[i] - response.unit.last_year_monthly_expenses[i],
          }
        }
        setIncomeData(data_);
      }
    } catch (err) {}
  };

  useEffect(() => {
    fetchIncomeData();
    fetchTasks();
  }, []);

  return (
    <div className="container">
      <div className="d-flex mt-4">
        <div className="me-2">
          {/* todo finish this */}
          {/* <div className="d-grid gap-1 d-flex mt-3">
            <button className="btn btn-light btn-sm active" type="button">1m</button>
            <button className="btn btn-light btn-sm" type="button">6m</button>
            <button className="btn btn-light btn-sm" type="button">YTD</button>
            <button className="btn btn-light btn-sm" type="button">1Y</button>
            <button className="btn btn-light btn-sm" type="button">5Y</button>
            <button className="btn btn-light btn-sm" type="button">Max</button>
          </div> */}
        <div className="d-flex mt-4 mb-4">
          <div className='me-4'>
            <CircularProgressBar currentValueDisplay={formatPriceInEUR(data.monthly_income)} currentValue={data.monthly_income} label="Income" />
          </div>
          <div className='me-4'>
            <CircularProgressBar currentValueDisplay={formatPriceInEUR(data.lifetime_expenses)} currentValue={data.lifetime_expenses} label="Expenses" />
          </div>
          <div className='me-4'>
            <CircularProgressBar currentValueDisplay={formatPriceInEUR(data.lifetime_net_profit)} currentValue={data.lifetime_net_profit} label="Profit" />
          </div>
        </div>
        <table className="key-value w-100 me-2 mt-2 w-100">
          <tbody>
            <tr>
              <td>Annual yield</td>
              <td>{formatPercentage(data.expected_anual_yield)}</td>
            </tr>
            <tr>
              <td>Monthly income weight</td>
              <td>{formatPercentage(data.monthly_income_weight)}</td>
            </tr>
            <tr>
              <td>Forecasted remaining income</td>
              <td>{formatPriceInEUR(data.forecasted_remaining_income)}</td>
            </tr>
            <tr>
              <td>Yearly fees</td>
              <td>{formatPriceInEUR(data.yearly_fees)}</td>
            </tr>
          </tbody>
        </table>
        <div className="mt-4 w-100">
          <h4>Tasks</h4>
          {tasks.length > 0 ? <TaskList data={tasks} /> : isTasksLoaded ? <div className="alert alert-info" role="alert">There are no tasks!</div> : <Loading />}
        </div>
      </div>
      <div className='w-100 ms-2'>
        <InfoBox text={data.full_address} />
        <FinancialInsights data={incomeData} title="Net income" showLegend={false} />        
      </div>
    </div>
  </div>
  )
}

export default UnitViewOverview
