import React from 'react'

const UnitNavigationRegularListItem = ({page, handleClick, currentPage}) => {
  if (page.hasOwnProperty('dropdownItems')) {
    return null;
  }
  const className_ = 'nav-link' + (
    currentPage === page.key ? ' active' : ''
  );
  return (
    <li className="nav-item">
      <a className={className_}
        onClick={
          () => handleClick(page.key)
      }>
        {
        page.label
      }</a>
    </li>
  )
}

export default UnitNavigationRegularListItem
