import React from 'react'
import FormStringElement from './elements/FormStringElement.js';
import FormIntElement from './elements/FormIntElement.js';
import FormNumberElement from './elements/FormNumberElement.js';
import FormFloatElement from './elements/FormFloatElement.js';
import FormTextareaElement from './elements/FormTextareaElement.js';
import FormSelectIntElement from './elements/FormSelectIntElement.js';
import FormSelectStringElement from './elements/FormSelectStringElement.js';
import FormPasswordElement from './elements/FormPasswordElement.js';
import FormHiddenElement from './elements/FormHiddenElement.js';
import FormDateElement from './elements/FormDateElement.js';
import FormCheckboxElement from './elements/FormCheckboxElement.js';
import FormFileElement from './elements/FormFileElement.js';
import FormSelectableCardListElement from './elements/selectable-card/FormSelectableCardListElement.js';
import FormAutocompleteElement from './elements/FormAutocompleteElement.js';

const FormElement = ({
  name,
  type,
  id,
  initialValue,
  onChangeHandler,
  exceptions,
  additionalClassName,
  isDisabled = false,
  isHidden = false,
  childElements = [],
  isChildElement = false,
  ...rest
}) => {
  let component;
  switch (type) {
    case 'string': component = <FormStringElement id={id}
      name={name}
      additionalClassName={additionalClassName}
      initialValue={initialValue}
      onChangeHandler={onChangeHandler}
      exceptions={exceptions}
      isDisabled={isDisabled}
      isHidden={isHidden}
    />
      break;
    
    case 'date': component = <FormDateElement id={id}
      name={name}
      additionalClassName={additionalClassName}
      initialValue={initialValue}
      onChangeHandler={onChangeHandler}
      exceptions={exceptions}
      isDisabled={isDisabled}
      isHidden={isHidden}
    />
      break;

    case 'int': component = <FormIntElement id={id}
      name={name}
      additionalClassName={additionalClassName}
      initialValue={initialValue}
      onChangeHandler={onChangeHandler}
      exceptions={exceptions}
      isDisabled={isDisabled}  
      isHidden={isHidden}
    />
      break;
    
    case 'number': component = <FormNumberElement id={id}
      name={name}
      additionalClassName={additionalClassName}
      initialValue={initialValue}
      onChangeHandler={onChangeHandler}
      exceptions={exceptions}
      isDisabled={isDisabled}  
      isHidden={isHidden}
    />
      break;

    case 'float': component = <FormFloatElement id={id}
      name={name}
      additionalClassName={additionalClassName}
      initialValue={initialValue}
      onChangeHandler={onChangeHandler}
      exceptions={exceptions}
      isDisabled={isDisabled}
      isHidden={isHidden}
    />
      break;

    case 'password': component = <FormPasswordElement id={id}
      name={name}
      additionalClassName={additionalClassName}
      initialValue={initialValue}
      onChangeHandler={onChangeHandler}
      exceptions={exceptions}
      isDisabled={isDisabled}
      isHidden={isHidden}
    />
      break;

    case 'textarea': component = <FormTextareaElement id={id}
      name={name}
      additionalClassName={additionalClassName}
      initialValue={initialValue}
      onChangeHandler={onChangeHandler}
      exceptions={exceptions}
      isDisabled={isDisabled}  
      isHidden={isHidden}
    />
      break;

    case 'select_int': component = <FormSelectIntElement id={id}
      name={name}
      additionalClassName={additionalClassName}
      exceptions={exceptions}
      options={
        rest.options
      }
      initialValue={initialValue}
      onChangeHandler={
        (e) => onChangeHandler !== undefined && onChangeHandler(e, name)
      }
      isDisabled={isDisabled}
      isHidden={isHidden}
    />
      break;
    
    case 'select_string': component = <FormSelectStringElement id={id}
      name={name}
      additionalClassName={additionalClassName}
      exceptions={exceptions}
      options={
        rest.options
      }
      initialValue={initialValue}
      onChangeHandler={
        (e) => onChangeHandler !== undefined && onChangeHandler(e, name)
      }
      isDisabled={isDisabled}  
      isHidden={isHidden}
    />
      break;

    case 'hidden': component = <FormHiddenElement id={id}
      name={name}
      value={initialValue}/>
      break;

    case 'checkbox': component = <FormCheckboxElement 
      id={id}
      name={name}
      additionalClassName={additionalClassName}
      exceptions={exceptions}
      initialValue={initialValue}
      onChangeHandler={onChangeHandler}
      isDisabled={isDisabled} 
      isHidden={isHidden}
    />
      break;

    case 'file': component = <FormFileElement 
        id={id}
        name={name}  
        isHidden={isHidden}
        onChangeHandler={onChangeHandler}
        accept={rest.accept}
        initialValue={initialValue}
      />
      break;

    case 'selectable_card_list': 
      component = <FormSelectableCardListElement
        id={id}
        name={name}  
        isHidden={isHidden}
        cards={rest.cards}
      />
    break;

    case 'autocomplete': 
      component = <FormAutocompleteElement
        id={id}
        initialValue={initialValue}
        name={name}  
        isHidden={isHidden}
        searchIdentifier={rest.searchIdentifier}
        selectedTags={rest.selectedTags}
        onChangeHandler={
          (e) => onChangeHandler !== undefined && onChangeHandler(e, name)
        }
        additionalClassName={additionalClassName}
      />
    break;
  }

  if (component === undefined) {
    throw new Error('Component of type ' + type + ' is not defined in FormElement')
  }

  return (
    <div className={`${isChildElement ? 'ms-1' : 'input-group has-validation'}`}>
      {component}
      {childElements}
      {exceptions.length > 0 && <div className="invalid-feedback">
        {exceptions[0]}
      </div>}
    </div>
  );
}

export default FormElement
