import React from 'react';
import { formatPriceInEUR, formatPercentage } from '../../services/Format.js';

const ListingCard = ({id, asking_rent_price, unit_buy_price, annual_yield, taxes_fees, url}) => {
  return (
    <div className="card mb-3"
      style={
        {maxWidth: "540px"}
    }>
      <div className="row g-0">
        <table className="key-value w-100 me-2 mt-2 w-100">
          <tbody>
            <tr>
              <td>Buy Price</td>
              <td>{formatPriceInEUR(unit_buy_price)}</td>
            </tr>
            <tr>
              <td>Taxes & fees</td>
              <td>{formatPriceInEUR(taxes_fees)}</td>
            </tr>
            <tr>
              <td>Total investment</td>
              <td>{formatPriceInEUR(taxes_fees + unit_buy_price)}</td>
            </tr>
            <tr>
              <td>Rent Income</td>
              <td>{formatPriceInEUR(asking_rent_price)}</td>
            </tr>
            <tr>
              <td>Annual yield</td>
              <td>{formatPercentage(annual_yield)}</td>
            </tr>
            <tr>
              <td>Links</td>
              <td><a target="_blank" href={url}>Fotocasa</a></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default ListingCard
