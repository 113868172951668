import {ACTION_LOGIN, ACTION_LOGOUT} from './actionTypes';

const defaultState = {
  'loggedInUser': {
    id: null,
    isLoggedIn: false,
    name: null,
    clearanceLevel: null,
    accountID: null,
  }
};


export default function reducer(state = defaultState, action) {

  switch (action.type) { // case actionTypes.actionLogin:
    case ACTION_LOGIN:
      const userObject = {
        id: parseInt(action.payload.id),
        isLoggedIn: true,
        name: action.payload.userName,
        clearanceLevel: action.payload.clearanceLevel,
        accountID: action.payload.accountID
      };
      localStorage.setItem('loggedInUser', JSON.stringify(userObject));
      return {
        loggedInUser: userObject
      }

    case ACTION_LOGOUT:
      localStorage.removeItem('loggedInUser');
      return defaultState;

    default:
      return state;
  }
}
