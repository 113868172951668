import React from 'react'
import IncomeNavigationRegularListItem from './IncomeNavigationRegularListItem.js';

const IncomeNavigationTabs = ({currentPage, handleClick, id}) => {

  const pages = [
    // {
    //   key: 'overview',
    //   label: 'Overview'
    // },
    {
      key: 'insights',
      label: 'Insights'
    },
    {
      key: 'calendar',
      label: 'Calendar'
    },
    // {
    //   key: 'income-forecasting',
    //   label: 'Forecasting'
    // },
    // {
    //   key: 'balance-sheet',
    //   label: 'Balance sheet'
    // },
    // {
    //   key: 'statements',
    //   label: 'Income Statements'
    // },
    // {
    //   key: 'expenses',
    //   label: 'Expenses'
    // },
    // {
    //   key: 'deposits',
    //   label: 'Deposits'
    // },
  ];

  return (
    <ul className="nav nav-tabs">
      {
      pages.map(page => {
        return <div key={
          page.key
        }>
          <IncomeNavigationRegularListItem page={page}
            handleClick={handleClick}
            currentPage={currentPage}/>
        </div>
    })
    } </ul>
  )
}

export default IncomeNavigationTabs
