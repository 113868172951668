import React from 'react'
import PageHeading from '../../components/common/PageHeading.js'
import Form from '../../services/Form.js'
import RouteActionType from '../../enums/RouteActionType.js'

const RegisterPage = ({id, action}) => {
  return <>
    <PageHeading heading="Create a new landlord account"/>
    <Form 
        formID="user-register"
        successMessage={action === RouteActionType.edit ? 'Data was updated' : 'Your account was created, you can now login'}
        objectID={parseInt(id) || 0}
      />
    </>
}

export default RegisterPage
