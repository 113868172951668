import GraphQLClientWithCredentials from "./GraphQLClientWithCredentials.js";
import { DO_ACTION } from "../graph-ql/action.js";

const ActionCaller = async ({object_id, action_type, loadingFunc, postProcessorFunc}) => {
  loadingFunc(true);
  const graphQLClient = GraphQLClientWithCredentials();
  let mutationData = {
    'object_id': object_id,
    'action_type': action_type,
  };
  
  try {
    await graphQLClient.request(DO_ACTION, mutationData);
    loadingFunc(false);
    postProcessorFunc();
  } catch (error) {
    console.error('Error performing mutation:', error);
    loadingFunc(false);
    throw error;
  }
}

export default ActionCaller
