import {React} from 'react'
import PageHeading from '../../../components/common/PageHeading.js'
import Form from '../../../services/Form.js'

const UnitMediaAddPage = ({id, extraData}) => {
  return (
    <>
      <PageHeading heading={'Add media assets'}/>
      <Form
        formID={'unit-media-add'}
        successMessage={'Media assets were added'}
        extraData={extraData}
      />
    </>
  )
}

export default UnitMediaAddPage
