import {gql} from 'graphql-request';

export const AUTOCOMPLETE = gql `query autocomplete($search_string: String!, $search_identifier: String!){
  autocomplete(
    search_string: $search_string,
    search_identifier: $search_identifier,
  ) {
    data {
      key,
      label
    }
  }
}`;