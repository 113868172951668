import React, {useState} from 'react'
import NoContentAlert from '../../../common/NoContentAlert.js';
import UnitIncomeTimelineView from './UnitIncomeTimelineView.js';
import UnitIncomeDetailsView from './UnitIncomeDetailsView.js';
import { formatOrdinalNumber, formatPriceInEUR } from '../../../../services/Format.js';
import RentAndYieldChart from '../chart/RentAndYieldChart.js';
import CircularProgressBar from '../../../common/chart/CircularProgressBar.js';

const UnitIncomeListTable = ({data, addIncomeToUnitHandlerFn, editIncomeToUnitHandlerFn, unit_data}) => {
  const [showDetails, setShowDetails] = useState(false);
  
  if (!data.length) {
    return <NoContentAlert 
      messageStringStart='There is no income!'
      messageStringEnd='to this property'
      callToActionString='Add your first income'
      callToActionFn={addIncomeToUnitHandlerFn}
    />
  }

  const generateRentAndYieldData = (rentData) => {
    // Helper to format date into year
    const getYear = timestamp => new Date(timestamp).getFullYear();
  
    // Step 1: Aggregate rent values by year
    const rentByYear = rentData.reduce((acc, { billing_timestamp, value }) => {
      const year = getYear(billing_timestamp);
      
      if (!acc[year]) {
        acc[year] = { totalRent: 0, count: 0 };
      }
      
      // Accumulate total rent and count of rent values for that year
      acc[year].totalRent += value;
      acc[year].count += 1;
  
      return acc;
    }, {});
  
    // Step 2: Build the final array of rent and yield data for the chart
    const result = Object.entries(rentByYear).map(([year, { totalRent, count }]) => {
      const averageRent = totalRent / count;
  
      // Mock calculation for yield (you can replace this logic with the real one)
      const yieldPercentage = (averageRent / 1000) * 5; // Example: arbitrary formula
  
      return {
        year: year,
        rentPrice: Math.round(averageRent), // Round the rent price for display purposes
        yield: yieldPercentage.toFixed(2) // Keep yield to 2 decimal places
      };
    });
    
    return result;
  }
  
  return <>
    {unit_data.hasOwnProperty('rent_due_days') && unit_data.rent_due_days.length > 0 && <div className="alert alert-info" role="alert">Next rent income of <b>{formatPriceInEUR(unit_data.monthly_income)}</b> is due on the <b>{unit_data.rent_due_days.map(formatOrdinalNumber).join(', ')}</b> of the month.</div>}
    <div className="d-flex mt-4 mb-4">
      <div className='me-4'>
        <CircularProgressBar currentValueDisplay={formatPriceInEUR(unit_data.lifetime_income)} currentValue={unit_data.lifetime_income} label={'Lifetime income'} />
      </div>
      <div className='me-4'>
        <CircularProgressBar currentValueDisplay={formatPriceInEUR(unit_data.ytd_income)} currentValue={unit_data.ytd_income} label={'Year-to-date income'} />
      </div>
      <div className='me-4'>
        <CircularProgressBar currentValueDisplay={formatPriceInEUR(unit_data.forecasted_remaining_income)} currentValue={unit_data.forecasted_remaining_income} label={'Remaining income'} />
      </div>
    </div>
    <h4>Income history</h4>
    <UnitIncomeTimelineView data={data} />
    <a
      className="d-flex flex-row-reverse mt-2 mb-2 icon-link icon-link-hover" style={{cursor: 'pointer'}}
      onClick={() => setShowDetails(showDetails ? false : true)}
    >{showDetails ? 'Hide' : 'Show'} details</a>
    {showDetails && <UnitIncomeDetailsView data={data} editIncomeToUnitHandlerFn={editIncomeToUnitHandlerFn} />}
    {/* todo finish this */}
    {/* <h4>Rent & Yield evolution</h4>
    <RentAndYieldChart data={generateRentAndYieldData(data)} /> */}
    
  </>
}

export default UnitIncomeListTable