import React, {useEffect, useState} from 'react'
import PageHeading from '../../components/common/PageHeading.js'
import store from '../../redux/store.js'
import {useNavigate} from 'react-router-dom'
import { useLocation } from "react-router-dom";
import {sleep} from '../../services/Utils.js'
import GraphQLClientWithCredentials from '../../services/GraphQLClientWithCredentials.js';
import { USER_LOGOUT } from '../../graph-ql/auth.js';

const LogoutPage = () => {
  const [sleepVal, setSleep] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const isLoggedIn = !location.state ? true : location.state?.isLoggedIn;
  const graphQLClient = GraphQLClientWithCredentials();

  useEffect(() => {
    store.dispatch({type: 'actionLogout', payload: {}});
    const logoutUser = async () => {
      await graphQLClient.request(USER_LOGOUT);
    };
    
    const sleepFn = async () => {
      await sleep(1500);
      setSleep(1);
    };
    logoutUser();
    sleepFn();
  }, []);

  if (sleepVal === 1) {
    navigate('/');
    window.location.reload();
  }

  const headingMessage = isLoggedIn ? "You are now logged out" : "Your session has expired, please log in again";

  return (
    <>
      <PageHeading heading={headingMessage}/>
      <p>See you soon!</p>
    </>
  )
}

export default LogoutPage
