import {gql} from 'graphql-request';

export const DELETE_UNIT = gql `mutation unit_delete(
  $id: ID!
) {
  unit_delete(id: $id) {
    data {
      id
    },
    result,
    exceptions {
        property,
        failed_rules {
            message
        }
    }
  }
}`

export const LIST_UNIT = gql `query unit_list($city_id: ID, $account_id: ID!){
  unit_list(city_id: $city_id, account_id: $account_id)
    {
    id, 
		account {
			id, 
			name, 
			creation_timestamp
		},  
		city {
			id, 
			name
		}, 
		identifier, 
		address, 
		identifier_image_url, 
		monthly_income, 
		monthly_income_weight, 
		time_in_portfolio_in_years, 
		expected_anual_yield, 
		lifetime_income, 
		ytd_income, 
		lifetime_expenses, 
		ytd_expenses, 
		lifetime_net_profit, 
		ytd_net_profit, 
		current_number_of_tenants,
		total_investment,
		yearly_fees,
		occupancy_rate,
		tenant_retention_rate
  }
}`;

export const VIEW_UNIT = gql `query view_unit(
  $id: ID!
){
  unit(id: $id) {
    id, 
		account {
			id, 
			name, 
			creation_timestamp
		},
		city {
			id, 
			name
		}, 
		identifier, 
		address, 
		identifier_image_url, 
		monthly_income, 
		monthly_income_weight, 
		time_in_portfolio_in_years, 
		expected_anual_yield, 
		lifetime_income, 
		ytd_income, 
		lifetime_expenses, 
		ytd_expenses, 
		lifetime_net_profit, 
		ytd_net_profit, 
		current_number_of_tenants, 
		image_urls,
		total_investment,
		yearly_fees,
		purchase_price,
		purchase_fees,
		tenant_retention_rate,
		tenant_contract_fulfillment_rate,
		occupancy_rate,
		remaining_contract_period_in_months,
		forecasted_remaining_income,
		rent_due_days,
		full_address,
  }
}`

// START MANUAL SECTION



// END MANUAL SECTION