import React from 'react'
import UnitNavigationRegularListItem from './UnitNavigationRegularListItem.js';
import UnitNavigationDropdownListItem from './UnitNavigationDropdownListItem.js';

const UnitNavigationTabs = ({currentPage, handleClick, id}) => {


  const pages = [
    {
      key: 'overview',
      label: 'Overview'
    },
    {
      key: 'income',
      label: 'Income'
    }, {
      key: 'expenses',
      label: 'Expenses'
    },
    {
      key: 'tenants',
      label: 'Tenants'
    }, 
    {
      key: 'inventory',
      label: 'Inventory'
    }, 
    // {
    //   key: 'mortgage',
    //   label: 'Mortgage'
    // }, 
    {
      key: 'listings',
      label: 'Listings'
    }, 
    {
      key: 'insurance',
      label: 'Insurance'
    },{
      key: 'utilities',
      label: 'Utilities'
    }, {
      key: 'contacts',
      label: 'Contacts'
    }, {
      key: 'media',
      label: 'Media'
    },
    {
      key: 'purchase',
      label: 'Purchase'
    }, 
    {
      key: 'tasks',
      label: 'Tasks'
    },
    // {
    //   key: 'documents',
    //   label: 'Documents',
    //   dropdownItems: [
    //     {
    //       key: 'floor-plan',
    //       label: 'Floor plan'
    //     }, {
    //       key: 'energy-certificate',
    //       label: 'Energy Certificate'
    //     }, {
    //       key: 'tax-forms',
    //       label: 'Tax Forms'
    //     }, /*{
    //       key: 'closing-statement',
    //       label: 'Closing statement'
    //     }*/
    //   ]
    // }
  ];

  return (
    <>
      <ul className="nav nav-tabs mb-3">
        {
        pages.map(page => {
          return <div key={
            page.key
          }>
            <UnitNavigationRegularListItem page={page}
              handleClick={handleClick}
              currentPage={currentPage}/>
            <UnitNavigationDropdownListItem page={page}
              handleClick={handleClick}
              currentPage={currentPage}/>
          </div>
      })
      } </ul>
    </>
  )
}

export default UnitNavigationTabs
